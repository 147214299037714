.section-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 52px;

  @media (max-width: 1200px) {
    flex-direction: column;
    width: auto;
  }

  &.centered {
    justify-content: center;
  }

  .section-header-left {
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;

      img.thumbnail {
        background: #282525;
        border-radius: 10px;
        object-fit: cover;
        width: 119px;
        height: 119px;
        margin-right: 30px;
      }

      .info {
        display: flex;
        flex-direction: column;

        h1 {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 50px;
          line-height: 75px;
          color: #FFFFFF;
        }
      }
    }

    .subtitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #FFFFFF;
      opacity: 0.4;
      white-space:pre-wrap;
    }
  }
}