.labeled-input {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 40px;
  align-items: stretch;

  .label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 15px;
  }

  input {
    height: 61px;
  }

  input, textarea {
    min-height: 61px;
    background: #0A0707;
    border: 1px solid #4F4F4F;
    border-radius: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    padding: 0 20px;
    color: #FFFFFF;
    &::placeholder {
      color: #999;
    }
    &:focus {
      outline: none;
      border-color: #E72313;
    }
  }

  textarea {
    width: auto;
    padding: 20px;
    height: 236px !important;
    resize: none;
  }

  &.error {
    input, textarea {
      border-color: #E72313;
      background-color: #E7231333;
    }
  }
}