.pyg-section {
  display: flex;
  flex-direction: column;

  .container{
    display: flex;
    flex-direction: column;

    .section {
      margin-bottom:3em;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      line-height: 22px;

      strong {
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        letter-spacing: 0.1em;
        display: block;
      }
    }

    .question {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 10px;
      font-family: 'Poppins', sans-serif;
      letter-spacing: 0.1em;
    }

    .answer {
      font-family: 'Poppins', sans-serif;
    }
  }
}