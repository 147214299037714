.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #E72313;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;

  &.small {
    width: 24px;
    height: 24px;
    border: 3px solid #E72313;

    &:after {
      width: 24px;
      height: 24px;
      border: 3px solid #E72313;
    }
  }

  &.white {
    border: 3px solid white;
    &:after {
      border: 3px solid white;
    }
  }
}
.loader:after {
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid #E72313;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% { transform: translate(-50%, -50%) scale(0) }
  60% , 100% { transform: translate(-50%, -50%)  scale(1)}
}
@keyframes pulse {
  0% , 60% , 100%{ transform:  scale(1) }
  80% { transform:  scale(1.2)}
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1200px) {
    min-height: 50vh;
  }
}