@import url("https://use.typekit.net/ymk0cvo.css");

.header-container {
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @keyframes header-popin {
    0% { transform: translateY(-20px); opacity: 0; }
    100% { transform: translateY(0px); opacity: 1; }
  }

    #logo {
      width: 180px;
      cursor: pointer;
      animation: header-popin 0.2s ease-in-out forwards;
      @media (max-width: 1200px) {
        margin: 0 auto;
      }

      font-family: "exocet", sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 32px;
      line-height: 35px;

      .subtitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.8em;
        text-transform: uppercase;
        color: #E72313;
        margin-left: 4px;
      }
    }

  .header-btn {
    transform: translateY(-20px);
    opacity: 0;
    animation: header-popin 0.2s ease-in-out forwards;
    &:hover {
      padding-bottom: 15px;
    }
  }

  .header-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1200px;
    @media (max-width: 1200px) {
      width: 100%;
    }
    // border: 1px solid white;

    .hamburger {
      cursor: pointer;
      margin: 0 auto;
    }

    nav {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      @media (max-width: 1200px) {
        display: none;
      }
    }
  }

  .mobile-menu-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: #000000cc;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(5px);

    .mobile-menu-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding-top: 40px;
      .hamburger {
        cursor: pointer;
        margin: 0 auto;
      }
    }

    .mobile-menu {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 40px;
      margin-top: 40px;
      width: 100%;

      .generic-button {
        background: none;
        height: 80px;

        .label {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 18.4094px;
          line-height: 28px;
          letter-spacing: 0.1em;
          color: #FFFFFF;
        }

        .icon {
          height: 50px;
          width: 50px;
          max-height: 50px;
          object-fit: contain;
          object-position: left;
        }
      }
    }
  }
}