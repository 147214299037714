.generic-button {
  background: #000000;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12.5px 21px;
  cursor: pointer;
  user-select: none;
  transition: all 0.05s ease-in;

  &.rounder {
    border-radius: 40px;
  }

  .icon {
    margin-right: 16.5px;
    max-height: 20px;
  }

  .label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    white-space: nowrap;
    text-transform: uppercase;
  }

  &.disabled {
    cursor: default;
    &.visibleDisabled {
      opacity: 0.5;
    }
  }

  &.loading {
    cursor: wait;
  }

  &:active:not(.disabled):not(.loading) {
    transform: translateY(3px);
  }

  &.withGlow {
    box-shadow: 0px 0px 84px rgba(231, 35, 19, 0.5);
  }
}