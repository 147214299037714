.header-btn {
  padding: 20px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;

  transition: all 0.05s ease-in;
  &:active {
    transform: translateY(3px) !important;
  }

  img {
    margin-right: 10px;
  }

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;

    /* identical to box height */
    letter-spacing: 0.1em;

    color: #FFFFFF;
    text-transform: uppercase;
    white-space: nowrap;
  }
}