.guild-header-right {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;

  .generic-button {
    margin-left: 37px;
  }

  @media screen and (max-width: 1200px) {
    align-items: flex-start;
    justify-content: space-between;
    .generic-button {
      margin-left: 0;
      margin-top: 40px;
    }
  }

}

.guild-section {
  .red-box-header {
    @media screen and (max-width: 1200px) {
      padding: 10px !important;
    }
  }
}

.discord-link {
  margin-top: 20px;
  user-select: none;
  cursor: pointer;
}

.guild-content {
  display: flex;
  background-color: #0A0707;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: 52px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .separator {
    width: 1px;
    background-color: #171414;

    @media screen and (max-width: 1200px) {
      height: 1px;
      width: 100%;
    }
  }

  .guild-content-inner {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .row {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .label, .value {
        margin-right: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #FFFFFF;
      }

      .value {
        font-weight: 700;
      }
    }

    .req-label {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #FFFFFF;
      padding-bottom: 12px;
    }

    .req-value {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
      opacity: 0.4;
      white-space:pre-wrap;
    }

    &.left {
    }

    &.right {
      align-items: center;
    }
  }
}