@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.lexo-guilds-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  // overflow: hidden;
  background-color: #141111;
  color: #fff;
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .content-container {
    display: flex;
    width: 100%;
    justify-content: center;

    .ad-container {
      background: grey;
      width: 160px;
      height: 600px;
      margin: 0 auto;
      background-image: url('./assets/ad.png');
      position: sticky;
      top: calc(50vh - 300px);

      @media screen and (max-width: 1200px) {
        position: static;
      }
    }

    .content {
      flex: 1;
      max-width: 1200px;
      @media screen and (max-width: 1200px) {
        padding: 0 20px;
      }
    }

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      .ad-container {
        width: 100%;
        height: 160px;
        margin: 20px 0;
      }
    }
  }
}

.mobile-only {
  @media screen and (min-width: 1200px) {
    display: none !important;
  }
}

.desktop-only {
  @media screen and (max-width: 1200px) {
    display: none !important;
  }
}

.lexo-guilds-dropdown {
  border-radius: 20px;
  padding: 16px;
}