.guild-management-section {
  .inner-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 96px;

    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }

    .section {
      display: block;

      .left-info {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 30px;
        @media screen and (max-width: 1200px) {
          display: block;
          align-items: flex-start;
          flex-direction: column;
          .labeled-input {
            margin-top: 40px;
          }
        }
      }
    }
  }

  .spaced {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;

    @media screen and (max-width: 1200px) {
      .generic-button {
        width: auto !important;
      }
    }
  }

  .platforms-container, .language-container {
    .label {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
      margin-bottom: 15px;
    }

    .lexo-dropdown {
      margin-bottom: 40px;
      background: #0a0707;
    }

    .platforms {
      display: flex;
      flex-direction: row;
      height: 40px;
      align-items: flex-end;
      margin-bottom: 64px;
      user-select: none;

      .platform {
        margin-right: 27px;
        position: relative;
        cursor: pointer;
        transition: transform 0.05s ease-in-out;
        &:active {
          transform: translateY(2px);
        }

        .platform-icon {
          height: 24px;
        }

        &.disabled {
          .platform-icon {
            opacity: 0.4;
          }
        }

        .icon {
          width: 18px;
          height: 18px;
          position: absolute;
          top: -9px;
          right: -12px;
        }
      }
    }
  }
} 