.login-register-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .inner-container {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .errors {
    @keyframes error-anim {
      0% { transform: translateX(0px); }
      25% { transform: translateX(-5px); }
      75% { transform: translateX(5px); }
      100% { transform: translateX(0px); }
    }
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    text-align: center;

    .error {
      animation: error-anim 0.3s ease-in-out forwards;
    }
  }

  .no-account {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: white;
    margin-top: 20px;
    text-align: center;

    .cta {
      font-weight: 700;
      cursor: pointer;
    }
  }

  input {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
    font-size: 16px;
    color: white;
    &::placeholder {
      color: #999;
    }

    background: #0A0707;
    border: 1px solid #4F4F4F;
    border-radius: 20px;
    height: 50px;
    padding: 0 20px;
    margin-bottom: 20px;

    &:focus {
      outline: none;
      border-color: #E72313;
    }
  }
}
