footer {
  margin-top: 65px;
  margin-bottom: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1200px) {
    margin-bottom: 20px;
  }

  .legal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .info {
    font-size: 13px;
    max-width: 1200px;
    margin-top: 40px;
    opacity: 0.4;
    padding: 0 20px;
    text-align: justify;
  }

  a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    opacity: 0.9;
    padding: 8px 21px;
    border-right: 1px solid #FFFFFF;
    cursor: pointer;
    user-select: none;
    text-decoration: none;

    &:active {
      opacity: 1;
    }

    &:last-child {
      border-right: none;
    }
  }
}