.toasts-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;

  @keyframes toast-fadein {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .toast {
    border-radius: 20px;
    padding: 15px 40px;
    background-color: #0A0707;
    border: 1px solid #FFFFFF20;
    margin-top: 20px;
    animation: toast-fadein 0.15s ease-out forwards;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;

    &.error {
      border: 1px solid #E72313;
    }
  }
}