.guild-apply-section {
  .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    .generic-button {
      width: 100px;
    }
  }

  .platforms-container {
    display: flex;
    align-items: flex-start;

    .label {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
      margin-right: 30px;
    }

    .platforms {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-bottom: 30px;
      user-select: none;

      .platform {
        margin-right: 27px;
        position: relative;
        cursor: pointer;
        transition: transform 0.05s ease-in-out;
        &:active {
          transform: translateY(2px);
        }

        .platform-icon {
          height: 24px;
        }

        &.disabled {
          .platform-icon {
            opacity: 0.4;
          }
        }

        .icon {
          width: 18px;
          height: 18px;
          position: absolute;
          top: -9px;
          right: -12px;
        }
      }
    }
  }
}