.tos-modal-container {
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: #00000066;
  backdrop-filter: blur(5px);

  .tos-modal {
    background: #0A0707;
    border-radius: 20px;
    padding: 40px;
    border: 1px solid #FFFFFF20;

    .header {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 40px;
    }

    .content {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 30px;
      margin-bottom: 40px;

      a {
        color: #E72313;
        text-decoration: none;
        font-weight: 600;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}