.faq-section {
  display: flex;
  flex-direction: column;

  .item {
    margin-bottom:3em;

    .question {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 10px;
      font-family: 'Poppins', sans-serif;
      letter-spacing: 0.1em;
    }

    .answer {
      font-family: 'Poppins', sans-serif;
    }
  }
}