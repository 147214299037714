.products-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;

    .product {
      display: flex;
      flex-direction: row;
      margin: 20px;
      align-items: center;
      user-select: none;
      cursor: pointer;
      transition: all 0.05s ease-in-out;
      
      &:hover {
        transform: translateY(-4px);
      }

      &:active {
        transform: translateY(2px);
      }

      &.soon {
        filter: grayscale(100%);
        opacity: 0.7;
        transform: none;
        cursor: default;
      }

      .product-name, .product-soon {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        text-transform: uppercase;
      }

      .product-soon {
        font-size: 13px;
        margin-left: 4px;
      }

      img {
        margin-right: 20px;
        height: 75px;
      }
    }

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      .product {
        margin: 40px;
      }
    }
  }
}