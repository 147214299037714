.profile-edit-section {
  display: flex;
  flex-direction: column;

  .left-info {
    display: flex;
    flex-direction: row;
  }

  .spaced {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .row {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }
  
  .column {
    display: flex;
    flex-direction: column;
    flex: 1;

    &:first-child {
      padding-right: 50px;
      @media screen and (max-width: 1200px) {
        padding-right: 0;
      }
    }

    &:last-child {
      padding-left: 50px;
      @media screen and (max-width: 1200px) {
        padding-left: 0;
      }
    }

    &.end {
      justify-content: flex-end;
      .labeled-input {
        flex: 0;
      }
    }
  }
}